import { createRouter, createWebHistory } from "vue-router";

const router = createRouter({
  // history: createWebHashHistory(),
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      name: 'Home',
      component: () => import('@/views/Home.vue')
    },
    {
      path: '/about',
      name: 'About',
      component: () => import('@/views/About.vue')
    },
    {
      path: '/join',
      name: 'Join',
      component: () => import('@/views/Join.vue')
    },
    {
      path: '/connect',
      name: 'Connect',
      component: () => import('@/views/Connect.vue')
    },
    {
      path: '/news',
      name: 'News',
      component: () => import('@/views/News.vue')
    },
    {
      path: '/news-item/:id',
      name: 'NewsItem',
      component: () => import('@/views/NewsItem.vue')
    }
  ] 
})


router.afterEach(() => {
  window.scrollTo(0, 0)
})

export default router