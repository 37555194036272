<template>
  <div id="app-box">
    <div class="header">
      <div class="header-container">
        <img src="@/assets/logo.png" class="logo-img" @click="$router.push('/')"/>
        <div class="nav-bar">
          <div v-for="(item, index) in nav_bar_list" 
               :key="index" 
               @click="$router.push(item.route)"
               :class="{active: $route.path === item.route}">{{item.title}}</div>
        </div>
        <div class="oa-btn"></div>
      </div>
    </div>
    <div style="margin-top: 80px;">
      <router-view />
    </div>
    
    <div class="footer">
      <div class="content">
        <div class="footer-name">
          <img src="./assets/logo-1.png" height="50px">
          <div class="name-cn">合肥合远信息技术有限公司</div>
          <div class="name-en">Heyuan Information Technology Co., Ltd.</div>
        </div>
        <div class="footer-solution">
          <div class="title">解决方案</div>
          <div class="item">运维类服务</div>
          <div class="item">软件开发类服务</div>
          <div class="item">智能终端类服务</div>
          <div class="item">大数据应用类服务</div>
        </div>
        <div class="footer-call">
          <div class="title">联系我们</div>
          <div class="item"><PhoneOutlined style="margin-right: 5px;" />0551-63362663</div>
          <div class="item"><SendOutlined style="margin-right: 5px;" />www.hyitc.com</div>
        </div>
        <div class="footer-about">
          <div class="title">关于我们</div>
          <div class="item" @click="$router.push('/about')">关于合远</div>
          <div class="item" @click="$router.push('/join')">加入我们</div>
          <div class="item" @click="goBeiAn">皖ICP备18007851号-2</div>
        </div>
        <div class="footer-focus">
          <div class="title">关注我们</div>
          <div class="qr-code"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { SendOutlined, PhoneOutlined } from '@ant-design/icons-vue'
  const nav_bar_list = [
    {
      title: '首页',
      route: '/'
    },
    {
      title: '关于我们',
      route: '/about'
    },
    // {
    //   title: '解决方案',
    //   route: '/solution'
    // },
    {
      title: '新闻动态',
      route: '/news'
    },
    {
      title: '加入我们',
      route: '/join'
    },
    {
      title: '联系我们',
      route: '/connect'
    }
  ] 
  function goBeiAn(){
    window.open('https://beian.miit.gov.cn/')
  }
</script>

<style lang="less" scoped>
#app-box {
  min-width: 1200px;
  max-width: 1980px;
  background-color: #fff;
  margin: 0px auto;
}

.header {
  width: 100%;
  height: 80px;
  background-color: #fff;
  box-sizing: border-box;
  display: flex;
  justify-content: center;

  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;

  .header-container {
    height: 100%;
    width: 1100px;
    padding: 0px 20px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .logo-img {
      cursor: pointer;
      height: 50px;
      margin-bottom: 10px;
    }

    .nav-bar {
      height: 50px;
      width: 70%;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      font-size: 18px;
      font-family: Source Han Sans CN, Source Han Sans CN;
      font-weight: 500;
      color: #333333;
      line-height: 26px;

      div {
        cursor: pointer;
        padding-bottom: 10px;
        border-bottom: 5px solid #fff;
      }
      .active {
        color: #409EFF;
        border-bottom: 5px solid #409EFF;
      }
    }

    .oa-btn {
      height: 50px;
      width: 10%;
    }
  }
}

.footer {
  width: 100%;
  background-color: #133354;
  display: flex;
  justify-content: center;
  padding-top: 80px;
  padding-bottom: 30px;
  .content {
    width: 1200px;
    height: 100%;
    background-color: #133354;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    color: #fff;
    .footer-name {
      width: 27%;
      height: 100%;

      .name-cn {
        font-size: 20px;
        font-family: Source Han Sans CN, Source Han Sans CN;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 29px;
        margin: 20px 0px 10px 0px;
      }

      .name-en {
        font-size: 12px;
        font-family: Source Han Sans CN, Source Han Sans CN;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 17px;
      }
    }

    .footer-solution {
      width: 15%;
      height: 100%;
      
      .title {
        border-bottom: 1px dashed #fff;
        padding-bottom: 10px;
        margin-bottom: 15px;
        font-size: 18px;
        font-family: Source Han Sans CN, Source Han Sans CN;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 26px;
      }

      .item {
        margin-bottom: 15px;
        font-size: 13px;
        cursor: pointer;
        font-size: 14px;
        font-family: Source Han Sans CN, Source Han Sans CN;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 20px;
      }
    }

    .footer-call {
      width: 15%;
      height: 100%;

      .title {
        border-bottom: 1px dashed #fff;
        padding-bottom: 10px;
        margin-bottom: 15px;
        font-size: 18px;
        font-family: Source Han Sans CN, Source Han Sans CN;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 26px;
      }

      .item {
        margin-bottom: 15px;
        font-size: 13px;
        cursor: pointer;
        font-size: 14px;
        font-family: Source Han Sans CN, Source Han Sans CN;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 20px;
      }
    }

    .footer-about {
      width: 15%;
      height: 100%;

      .title {
        border-bottom: 1px dashed #fff;
        padding-bottom: 10px;
        margin-bottom: 15px;
        font-size: 18px;
        font-family: Source Han Sans CN, Source Han Sans CN;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 26px;
      }

      .item {
        margin-bottom: 15px;
        font-size: 13px;
        cursor: pointer;
        font-size: 14px;
        font-family: Source Han Sans CN, Source Han Sans CN;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 20px;
      }
    }

    .footer-focus {
      width: 10%;
      height: 100%;

      .title {
        padding-bottom: 10px;
        margin-bottom: 15px;
        font-size: 18px;
        font-family: Source Han Sans CN, Source Han Sans CN;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 26px;
      }

      .qr-code {
        background-image: url('@/assets/qr-code.png');
        background-size: 100% 100%;
        width: 100px;
        height: 100px;
      }
    }
  }
}
</style>
