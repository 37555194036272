import { createApp } from 'vue'
import App from './App.vue'
import router from './router.js'

import VMdPreviewHtml from '@kangc/v-md-editor/lib/preview-html';
import '@kangc/v-md-editor/lib/style/preview-html.css';

import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/reset.css';

createApp(App)
  .use(router)
  .use(VMdPreviewHtml)
  .use(Antd)
  .mount('#app')
